import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage as FM } from 'react-intl';
import { ModalHOC } from 'common';
import { ReloadIcon, CrossIcon } from 'common/icons';
import {
  LoginCell,
  ChallengeInfoElem,
  MoneyCell,
  ActionsButton,
  Tooltip,
  DefaultButton,
  BasicModal,
} from 'common/components';

import { archiveChallenge, restartChallenge } from 'client/challenges/_redux';
import { getAccountTradeLink } from 'client/accounts/_redux';
import { staticStyles } from './style';

import ChallengeProgress from './ChallengeProgress';
import { closeModal, openModal } from '../../../../interface/user/_redux/actions';
import OpenTradeLinkPanel from '../../../_components/OpenTradeLinkPanel';

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

// FIXME: Пытался кастомны джсом но не мог зацепиться, пока так но убрать конечно все что связано с хиро в ближайшем будущем
const isHero =
  document.location.hostname === 'portal.herofx.co' || document.location.hostname === 'herodev.prod.uptr.dev';

const ChallengeItem = ({
  activeChallenge,
  archiveChallenge,
  getAccountTradeLink,
  noRedirect,
  restartChallenge,
  showModal,
  history,
  modals,
  openModal,
  closeModal,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const updateWindowWidth = debounce(() => {
    setWindowWidth(window.innerWidth);
  }, 50);

  useEffect(() => {
    window.addEventListener('resize', updateWindowWidth);

    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);
  return (
    <div className="ChallengeAccountsItemWrapper">
      <div className="ChallengeAccountsItem__Buttons ChallengeAccountsItem__Buttons--Mobile">
        <ActionsButton
          tooltipId="Restart__tooltip"
          tooltipTextId={isHero ? 'justRestartChallengeHero' : 'justRestartChallenge'}
          deleteType
          onClickFunc={() => {
            showModal({
              caption: { id: isHero ? 'justRestartChallengeHERO10X' : 'justRestartChallenge' },
              content: {
                id: isHero
                  ? 'justAreYouSureYouWantToRestartThisChallengeHERO10X'
                  : 'justAreYouSureYouWantToRestartThisChallenge',
              },
              actionButton: {
                className: 'challenge-button',
                textId: isHero ? 'justRestartChallengeHERO10X' : 'justRestartChallenge',
                handler: () => restartChallenge(activeChallenge.challenge.id),
              },
              cancelButton: null,
            });
          }}>
          <div className="ReloadIcon">
            <ReloadIcon />
          </div>
        </ActionsButton>
        <ActionsButton
          tooltipId="Delete__tooltip"
          tooltipTextId="justArchiveChallenge"
          deleteType
          onClickFunc={() => {
            showModal({
              modalClassName: 'challenge-modal',
              caption: { id: 'justArchiveChallenge' },
              content: { id: 'justAreYouSureYouWantToArchiveThisChallenge' },
              actionButton: {
                textId: 'justArchive',
                type: 'error',
                handler: () => archiveChallenge(activeChallenge.id),
              },
              cancelButton: null,
            });
          }}>
          <div className="CrossIconDiv">
            <CrossIcon />
          </div>
        </ActionsButton>
      </div>
      <button
        type="button"
        className="ChallengeAccountsItem"
        style={{ cursor: 'default' }}
        onClick={() => {
          if (noRedirect) {
            return;
          }

          history.push(`/challenges/${activeChallenge.id}`);
        }}>
        <LoginCell challenge title={activeChallenge.challenge.title} login={activeChallenge.account.login} />
      </button>
      <div className="ChallengeAccountsItem ChallengeAccountsItem--Right">
        <div className="ChallengeAccountsItem__Metrics">
          <div className="ChallengeAccountsItem__Metrics-Item">
            <FM id="justEquity">{text => <span>{text}:</span>}</FM>{' '}
            <MoneyCell style={{ fontWeight: 500 }} heightMaxContent value={activeChallenge.equity} />
          </div>
          <div className="ChallengeAccountsItem__Metrics-Item displayNoneHero">
            <FM id="justTotalProfit">{text => <span>{text}:</span>}</FM>
            <MoneyCell
              style={{ fontWeight: 500 }}
              success={+activeChallenge.totalProfit.amount >= 0}
              danger={+activeChallenge.totalProfit.amount < 0}
              heightMaxContent
              value={activeChallenge.totalProfit}
            />
          </div>
          <div className="ChallengeAccountsItem__Metrics-Item">
            <FM id="justDailyProfit">{text => <span>{text}:</span>}</FM>
            <MoneyCell
              style={{ fontWeight: 500 }}
              success={+activeChallenge.dailyProfit.amount >= 0}
              danger={+activeChallenge.dailyProfit.amount < 0}
              heightMaxContent
              value={activeChallenge.dailyProfit}
            />
          </div>
          {activeChallenge.totalProfit.amount < 0 && (
            <div className="ChallengeAccountsItem__Metrics-Item displayNoneHero">
              <FM id="justProfitTarget">{text => <span>{text}:</span>}</FM>
              <MoneyCell style={{ fontWeight: 500 }} danger heightMaxContent value={activeChallenge.level.target} />
            </div>
          )}
        </div>
        <div className="ChallengeAccountsItem__Buttons ChallengeAccountsItem__Buttons--Desctop">
          <ActionsButton
            tooltipId="Restart__tooltip"
            tooltipTextId={isHero ? 'justRestartChallengeHERO10X' : 'justRestartChallenge'}
            deleteType
            onClickFunc={() => {
              showModal({
                caption: { id: isHero ? 'justRestartChallengeHERO10X' : 'justRestartChallenge' },
                content: { id: 'justAreYouSureYouWantToRestartThisChallenge' },
                actionButton: {
                  className: 'challenge-button',
                  textId: isHero ? 'justRestartChallengeHERO10X' : 'justRestartChallenge',
                  handler: () => restartChallenge(activeChallenge.challenge.id),
                },
                cancelButton: null,
              });
            }}>
            <div className="ReloadIcon">
              <ReloadIcon />
            </div>
          </ActionsButton>
          <ActionsButton
            tooltipId="Delete__tooltip"
            tooltipTextId="justArchiveChallenge"
            deleteType
            onClickFunc={() => {
              showModal({
                caption: { id: 'justArchiveChallenge' },
                content: { id: 'justAreYouSureYouWantToArchiveThisChallenge' },
                actionButton: {
                  textId: 'justArchive',
                  type: 'error',
                  handler: () => archiveChallenge(activeChallenge.id),
                },
                cancelButton: null,
              });
            }}>
            <div className="CrossIconDiv">
              <CrossIcon />
            </div>
          </ActionsButton>
        </div>
      </div>
      <div className="ChallengeAccountsItem">
        <ChallengeInfoElem>{activeChallenge.title}</ChallengeInfoElem>
        <ChallengeInfoElem innerDotColor="blue">{activeChallenge.level.name}</ChallengeInfoElem>
        <ChallengeInfoElem borderColor="#0AA1E2">
          {activeChallenge.challenge?.initialAccountBalance && (
            <MoneyCell
              style={{ fontWeight: 500 }}
              heightMaxContent
              value={activeChallenge.challenge?.initialAccountBalance}
            />
          )}
        </ChallengeInfoElem>
      </div>

      <div
        className="ChallengeAccountsItem ChallengeAccountsItem--Right ChallengeAccountsItem__Chart"
        // style={{ scale: `${windowWidth < 450 ? `${(windowWidth / 450) * 100 - 15}%` : '100%'}` }}
      >
        <ChallengeProgress activeChallenge={activeChallenge} windowWidth={windowWidth} />
      </div>
      <div className="ChallengeAccountsItem ChallengeAccountsItem--EmptyCell" />
      <div className="ChallengeAccountsItem ChallengeAccountsItem--WebTerminalButton">
        {activeChallenge.account?.webTerminal && (
          <Tooltip
            id="clientTradeTooltip"
            textId={activeChallenge.account?.isBlocked ? 'clientTradeBlockedAccount' : ''}>
            <DefaultButton
              textId="clientTrade"
              filled
              onClick={() => {
                getAccountTradeLink({ accountId: activeChallenge.account.id });
                openModal('openTradeLink');
              }}
            />
          </Tooltip>
        )}
      </div>
      <BasicModal
        captionId="accountOpenTradeLink"
        isOpen={modals.openTradeLink}
        onRequestClose={() => closeModal('openTradeLink')}>
        {modals.openTradeLink && <OpenTradeLinkPanel onTradingStarted={() => closeModal('openTradeLink')} />}
      </BasicModal>
      <style jsx>{staticStyles}</style>
    </div>
  );
};

export default compose(
  ModalHOC,
  withRouter,
  connect(
    state => ({
      modals: state.user.modals,
    }),
    {
      archiveChallenge: id => archiveChallenge.request(id),
      restartChallenge: id => restartChallenge.request(id),
      getAccountTradeLink: id => getAccountTradeLink.request(id),
      openModal: name => openModal(name),
      closeModal: name => closeModal(name),
    }
  )
)(ChallengeItem);
